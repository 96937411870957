<script>
import CardComponent from '@/components/CardComponent'

export default {
  components: {
    CardComponent,
  },
  props: {
    addresses: {
      type: Array,
      default: () => [],
    },
  },
}
</script>

<template>
  <CardComponent :title="$t('addresses')" icon="map-marker-radius">
    <b-table v-if="addresses.length" :data="addresses" class="no-wrap">
      <b-table-column v-slot="props" :label="$t('addressName')" field="name">
        {{ props.row.name }}
      </b-table-column>
      <b-table-column v-slot="props" :label="$t('address')" field="address">
        {{ props.row.address }}
      </b-table-column>
      <b-table-column v-slot="props" :label="$t('location')" field="location">
        <b-button
          size="is-small"
          icon-right="google-maps"
          tag="a"
          target="_blank"
          :title="props.row.name"
          :href="`https://www.google.com/maps/search/${props.row.location}`"
        />
      </b-table-column>
    </b-table>
    <template v-else>
      <div class="content has-text-grey has-text-centered">
        <p>
          <b-icon icon="table" size="is-large" />
        </p>
        <p>{{ $t('nodata') }}</p>
      </div>
    </template>
  </CardComponent>
</template>
